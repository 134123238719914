import store from '../../store'
import { dispatchToStore, commitToStore } from '@apicase/vue'
import { ApiService, ApiObjectTree } from '@apicase/services'
import fetch from '@apicase/adapter-fetch'
import ApiRequest from './request'
import localforage from 'localforage'

let loginRequest = ApiRequest.extend({
  method: 'POST',
  url: `admin/login`,
  meta: {isLogin: true}
})
loginRequest
  .on("done", (response: any) => {
    console.log(response)
  })

let loginDo = ApiRequest.extend({
  method: 'GET',
  url: `admin/login/:key`,
  meta: {isLogin: true}
})
loginDo
  .on("done", (response: any) => {
    store.dispatch('setToken', response.body.token)
    localforage.setItem('token', response.body.token)
    if (response.body.token?.length) {
      store.dispatch('setIsLoggedIn', true)
    }
  })

let getUserVariables = ApiRequest.extend({
  method: 'GET',
  url: `admin/user-variables/:userId`
})
getUserVariables
  .on("done", (response: any) => {
    store.dispatch('setStatesList', response.body)
  })

let getGifts = ApiRequest.extend({
  method: 'GET',
  url: `admin/gifts/:userId`
})
getGifts
  .on("done", (response: any) => {
    store.dispatch('setGiftsList', response.body)
  })

let createGift = ApiRequest.extend({
  method: 'POST',
  url: `admin/gifts/:userId`
})
createGift
  .on("done", (response: any) => {
    console.log(response)
    store.dispatch('appendGiftsList', response.body)
  })

let getStatsBase = ApiRequest.extend({
    method: 'GET',
    url: `admin/stats/base`
  })
  getStatsBase
  .on("done", (response: any) => {
    store.dispatch('setStatsBase', response.body)
  })

let getStatsEfficiency = ApiRequest.extend({
    method: 'GET',
    url: `admin/stats/efficiency`
  })
  getStatsEfficiency
  .on("done", (response: any) => {
    store.dispatch('setStatsEfficiency', response.body)
  })

let getStatsFinancial = ApiRequest.extend({
  method: 'GET',
  url: `admin/stats/financial`
})
getStatsFinancial
.on("done", (response: any) => {
  store.dispatch('getStatsFinancial', response.body)
})

export default {
  loginRequest: loginRequest.doRequest,
  loginDo: loginDo.doRequest,
  getUserVariables: getUserVariables.doRequest,
  getGifts: getGifts.doRequest,
  createGift: createGift.doRequest,
  Stats: {
    getBase: getStatsBase.doRequest,
    getEfficiency: getStatsEfficiency.doRequest,
    getFinancial: getStatsFinancial.doRequest
  }
}
