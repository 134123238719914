

































































import Vue from 'vue'
import { mapState, mapActions, mapGetters } from 'vuex'
import Api from './api'

export default Vue.extend({
  name: 'App',

  data: () => ({
    loginSheet: false,
    loginRequestSent: false,
    login: ''
  }),
  computed: {
    ...mapGetters(["getIsLoggedIn"])
  },
  methods: {
    requestLogin() {
      Api.Rest.Admin.loginRequest({body: {email: this.login}})
      this.loginRequestSent = true
      // Api.Rest.Admin.loginDo.doRequest({params:{key: 'test'}})
    }
  },
  watch: {
    getIsLoggedIn: {
      handler() {
        this.loginSheet = !this.getIsLoggedIn
      },
      immediate: true
    }
  }
})
