
































import Api from '@/api'
import Vue from 'vue'
import { mapState, mapActions, mapGetters } from 'vuex'
import store from '@/store'

export default Vue.extend({
  name: 'States',
  data: () => ({
    userId: null
  }),
  computed: {
    ...mapState({
      statesList: (state:any) => state.statesList
    })
  },
  methods: {
    open() {
      Api.Rest.Admin.getUserVariables({ params: { userId: this.userId } })
    }
  }
})
