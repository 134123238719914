import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import localforage from 'localforage'

Vue.config.productionTip = false

localforage.getItem('token')
  .then((item:any) => {
    store.state.token = item
    if (item) {
      store.state.loggedIn = true
    }

    new Vue({
      router,
      store,
      vuetify,
      render: h => h(App)
    }).$mount('#app')    
  })
