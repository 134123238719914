import store from '../../store'
// import { dispatchToStore, commitToStore } from '@apicase/vue'
import { ApiService, ApiObjectTree } from '@apicase/services'
import adapter from '@apicase/adapter-fetch'
// import adapter from '@apicase/adapter-xhr'
import { apiUrl } from '@/helpers/config'
// import adapter from '@/helpers/apicase-adapter-xhr'

interface IApiService {
  doRequest(options?:object): any
  doSingleRequest(options?:object): any
  doUniqueRequest(options?:object): any
  extend(newOptions:object): IApiService
  off(evt:any, cb:Function): any
  on(evt:any, cb:Function): any
  once(evt:any, cb:Function): any
  pushRequest(options:object): any
  queue: []
  use(cb:Function): any
}

const ApiRequest:IApiService = new ApiService({ adapter, url: apiUrl, mode: 'cors', credentials: 'include', meta: { store },
  hooks: {
    before(ctx: any) {
      // console.log(ctx)
      if (!ctx.meta.isLogin && !store.getters.getIsLoggedIn) {
        store.dispatch('setIsLoggedIn', false)
        ctx.fail(ctx.payload)
      } else {
        ctx.payload.headers = {
          token: store.state.token
        }
        ctx.next(ctx.payload)
      }
    },
    done (ctx: any) {
      ctx.next(ctx.result)
    },
    fail (ctx:any) {
      console.log("fail", arguments)
      ctx.next(ctx.result)
    }
  }
})

export default ApiRequest
