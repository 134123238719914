import Api from '@/api'
import store from '@/store'
import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import StatesView from '../views/StatesView.vue'
import StatsView from '../views/StatsView.vue'
import GiftsView from '../views/GiftsView.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/variables',
    name: 'variables',
    component: StatesView,
    // beforeEnter: async (to, from, next) => {
    //   if (store.getters.getIsLoggedIn) {
    //     next()
    //   } else {
    //     return false
    //   }
    // },
  },
  {
    path: '/gifts',
    name: 'gifts',
    component: GiftsView,
  },
  {
    path: '/stats',
    name: 'stats',
    component: StatsView,
  },
  {
    path: '/login/:key',
    name: 'login',
    meta: {isLoginPath: true},
    component: HomeView,
    beforeEnter: async (to, from, next) => {
      await Api.Rest.Admin.loginDo({ params: { key: to.params.key } })
      next({name: 'variables'})
    },
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.meta?.isLoginPath || store.getters.getIsLoggedIn) {
    next()
  }
  return false
})

export default router
