















































































































































































































import Api from '@/api'
import Vue from 'vue'
import { mapState, mapActions, mapGetters } from 'vuex'
import store from '@/store'
import * as moment from 'moment'

export default Vue.extend({
  name: 'Gifts',
  data: () => ({
    userId: null,
    createDialog: false,
    createGiftName: 'LC_CRYSTAL',
    createGiftOperation: 'add',
    createGiftValue: 0,
    createGiftAvDateMenu: false,
    createGiftAvDate: moment.utc().format('YYYY-MM-DD'),
    createGiftAvTimeMenu: false,
    createGiftAvTime: moment.utc().format('HH:mm:ss')
  }),
  computed: {
    ...mapState({
      giftsList: (state:any) => state.giftsList
    })
  },
  methods: {
    open() {
      Api.Rest.Admin.getGifts({ params: { userId: this.userId } })
    },
    createGift() {
      this.createDialog = false
      Api.Rest.Admin.createGift({params: { userId: this.userId }, body: {
        name: this.createGiftName, 
        operation: this.createGiftOperation, 
        value: this.createGiftValue,
        availableDate: `${this.createGiftAvDate} ${this.createGiftAvTime}`
      }})
      // Api.Rest.Admin.getGifts({ params: { userId: this.userId } })
    }
  }
})
