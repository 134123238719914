




























































































































































































































































































































































import Api from '@/api'
import Vue from 'vue'
import { mapState, mapActions, mapGetters } from 'vuex'
import store from '@/store'

export default Vue.extend({
  name: 'Stats',
  data: () => ({
    tabs: 0,
    country: null,
    locale: null,
    countries: <{ text: string; value: string|null; }[]>[
    ],
    locales: <{ text: string; value: string|null; }[]>[
      {text: 'All', value: null},
      {text: 'RU', value: 'RU'},
      {text: 'EN', value: 'EN'},
    ]
  }),
  computed: {
    ...mapState({
      statsBase: (state:any) => state.statsBase,
      statsEfficiency: (state:any) => state.statsEfficiency,
      statsFinancial: (state:any) => state.statsFinancial,
    }),
    regs() {
      if (!this.statsBase) return []
      let regLife: {country: string, quantity: number}[] = (this.statsBase?.regLife || [])

      interface IRegs {country: string, d1: number, d7: number, d30: number, life: number}
      let totalD1 = 0
      let totalD7 = 0
      let totalD30 = 0
      let totalLife = 0

      let regs:IRegs[] = regLife.map((r:{country:string, quantity: number}) => {
        return {country: r.country, d1: 0, d7: 0, d30: 0, life: r.quantity}
      })
      
      ;(this.statsBase?.reg30 || []).forEach((r:{country:string, quantity: number}) => {
        let reg = regs.find(rr => rr.country === r.country)
        if (reg) reg.d30 = r.quantity
      })
      ;(this.statsBase?.reg7 || []).forEach((r:{country:string, quantity: number}) => {
        let reg = regs.find(rr => rr.country === r.country)
        if (reg) reg.d7 = r.quantity
      })
      ;(this.statsBase?.reg1 || []).forEach((r:{country:string, quantity: number}) => {
        let reg = regs.find(rr => rr.country === r.country)
        if (reg) reg.d1 = r.quantity
      })

      for (let reg of regs) {
        totalD1 = totalD1 + reg.d1
        totalD7 = totalD7 + reg.d7
        totalD30 = totalD30 + reg.d30
        totalLife = totalLife + reg.life
      }

      regs.sort((a,b) => a.d1 < b.d1 ? 1 : -1)

      this.countries = [{text: 'All', value: null}, ...regs.map(r => ({text: r.country, value: r.country}))]

      regs.unshift({country: 'TOTAL', d1: totalD1, d7: totalD7, d30: totalD30, life: totalLife})

      return regs
    },
    retention() {
      if (!this.statsBase) return []
      return this.statsBase.retention
    },
    arpu() {
      if (!this.statsFinancial) return []
      return this.statsFinancial.arpu
    },
    ltv() {
      if (!this.statsFinancial) return []
      return this.statsFinancial.ltv
    },
    completion() {
      if (!this.statsEfficiency) return []
      return this.statsEfficiency.completion
    },
    moneySpend() {
      if (!this.statsEfficiency) return []
      let spends:{scriptId: number, amount?: number, spend1?: number, spend7?: number, spend30?: number, spendLife?: number}[] = this.statsEfficiency.moneySpend.spendLife
      spends.forEach(s => s.spendLife = s.amount)

      for (let ms of <{scriptId: number, amount: number}[]>this.statsEfficiency.moneySpend.spend30) {
        let s = spends.find((s) => s.scriptId === ms.scriptId)
        if (!s) {
          s = {scriptId: ms.scriptId, amount: 0, spendLife: 0}
          spends.push(s)
        }
        s.spend30 = ms.amount
      }
      for (let ms of <{scriptId: number, amount: number}[]>this.statsEfficiency.moneySpend.spend7) {
        let s = spends.find((s) => s.scriptId === ms.scriptId)
        if (!s) {
          s = {scriptId: ms.scriptId, amount: 0, spendLife: 0}
          spends.push(s)
        }
        s.spend7 = ms.amount
      }
      for (let ms of <{scriptId: number, amount: number}[]>this.statsEfficiency.moneySpend.spend1) {
        let s = spends.find((s) => s.scriptId === ms.scriptId)
        if (!s) {
          s = {scriptId: ms.scriptId, amount: 0, spendLife: 0}
          spends.push(s)
        }
        s.spend1 = ms.amount
      }
      return spends
    },
    paypointStats() {
      if (!this.statsEfficiency) return []
      return this.statsEfficiency.paypointStats
    },
    revenues() {
      if (!this.statsBase) return []
      let revenueLife: {country: string, amount: number}[] = (this.statsBase?.revenueLife || [])

      interface IRevs {country: string, d1: number, d7: number, d30: number, life: number}
      let totalD1 = 0
      let totalD7 = 0
      let totalD30 = 0
      let totalLife = 0

      let revs:IRevs[] = revenueLife.map((r:{country:string, amount: number}) => {
          return {country: r.country, d1: 0, d7: 0, d30: 0, life: r.amount}
      })
      
      ;(this.statsBase?.revenue30 || []).forEach((r:{country:string, amount: number}) => {
        let rev = revs.find(rr => rr.country === r.country)
        if (rev) rev.d30 = r.amount
      })
      ;(this.statsBase?.revenue7 || []).forEach((r:{country:string, amount: number}) => {
        let rev = revs.find(rr => rr.country === r.country)
        if (rev) rev.d7 = r.amount
      })
      ;(this.statsBase?.revenue1 || []).forEach((r:{country:string, amount: number}) => {
        let rev = revs.find(rr => rr.country === r.country)
        if (rev) rev.d1 = r.amount
      })
      revs.sort((a,b) => a.d1 < b.d1 ? 1 : -1)

      for (let rev of revs) {
        totalD1 = totalD1 + rev.d1
        totalD7 = totalD7 + rev.d7
        totalD30 = totalD30 + rev.d30
        totalLife = totalLife + rev.life
      }

      revs.unshift({country: 'TOTAL', d1: Math.round(totalD1), d7: Math.round(totalD7), d30: Math.round(totalD30), life: Math.round(totalLife)})
      return revs
    }
  },
  methods: {
    loadData() {
      let query:any = {}
      if (this.country) query.country = this.country
      if (this.locale) query.locale = this.locale

      Api.Rest.Admin.Stats.getBase({query})
      // if (this.tabs === 0) {
      //   Api.Rest.Admin.Stats.getBase({query})
      // }
      if (this.tabs === 1) {
        Api.Rest.Admin.Stats.getEfficiency({query})
      }
      if (this.tabs === 2) {
        Api.Rest.Admin.Stats.getFinancial({query})
      }
    }
  },
  watch: {
    tabs: {
      handler() {
        // @ts-ignore
        this.loadData()
      },
      immediate: true
    }
  }
})
