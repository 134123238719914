import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loggedIn: false,
    token: null,
    statesList: [],
    statsBase: null,
    statsEfficiency: null,
    statsFinancial: null,
    giftsList: []
  },
  getters: {
    getIsLoggedIn: state => state.loggedIn,
  },
  mutations: {
    SET_IS_LOGGEDIN (state) {
      state.loggedIn = true
    },
    SET_IS_LOGGEDOFF (state) {
      state.loggedIn = false
    },
    SET_TOKEN(state, value) {
      state.token = value
    },
    SET_STATESLIST(state, value) {
      state.statesList = value
    },
    SET_GIFTSLIST(state, value) {
      state.giftsList = value
    },
    SET_STATSBASE(state, value) {
      state.statsBase = value
    },
    SET_STATSEFFICIENCY(state, value) {
      state.statsEfficiency = value
    },
    SET_STATSFINANCIAL(state, value) {
      state.statsFinancial = value
    },
  },
  actions: {
    setToken({ commit }, token) {
      commit('SET_TOKEN', token)
    },
    setIsLoggedIn({ commit }, online) {
      if (online) commit('SET_IS_LOGGEDIN')
      else commit('SET_IS_LOGGEDOFF')
    },
    setStatesList({ commit }, list) {
      commit('SET_STATESLIST', list)
    },
    setStatsBase({ commit }, data) {
      commit('SET_STATSBASE', data)
    },
    setStatsEfficiency({ commit }, data) {
      commit('SET_STATSEFFICIENCY', data)
    },
    getStatsFinancial({ commit }, data) {
      commit('SET_STATSFINANCIAL', data)
    },
    setGiftsList({ commit }, list) {
      commit('SET_GIFTSLIST', list)
    },
    appendGiftsList({ commit, state }, item) {
      let list = [item, ...state.giftsList]
      commit('SET_GIFTSLIST', list)
    },
  },
  modules: {
  }
})
